@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Ariel Script";
  font-display: swap;
  src: url("../public/fonts/ariel-script/ariel-script.otf") format("otf"),
  url("../public/fonts/ariel-script/ariel-script.ttf") format("truetype");
}

@font-face {
  font-family: "Bullet Journal";
  font-display: swap;
  src: url("../public/fonts/bullet-journal/bullet-journal.otf") format("otf"),
  url("../public/fonts/bullet-journal/bullet-journal.ttf") format("truetype");
}

@font-face {
  font-family: "Hoefler Text";
  font-display: swap;
  src: url("../public/fonts/hoefler-text/hoefler-text.ttf") format("truetype");
}
